import React from "react";

import AuthRoutes from "./auth.routes";
import AdminRoutes from "./admin.routes";
import SincoRoutes from "./sinco.routes";

import { useAuth } from "../contexts/auth";
import StatusMsg from "../../src/components/StatusComp";

const Routes: React.FC = () => {
  const { signed, loading, isAdmin } = useAuth();
  // statusServer

  // if (!statusServer) {
  //   return (
  //     <StatusMsg
  //       title="Network Error"
  //       description="Erro inesperado, feche a página e tente novamente, caso o erro continue entre em contato com a FESG - (64) 3495-8124"
  //     />
  //   )
  // }

  if (loading) {
    return <StatusMsg title="Carregando" description="Aguarde" />;
  }

  return signed ? isAdmin ? <AdminRoutes /> : <SincoRoutes /> : <AuthRoutes />;
};

export default Routes;

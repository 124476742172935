import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import Landing from "../pages/AuthPages/Landing";
// import Login from "../pages/AuthPages/Login";
// import Subscription from "../pages/AuthPages/Subscription";
// import SubscriptionCpf from "../pages/AuthPages/SubscriptionCpf";
// import ForgetPassword from "../pages/AuthPages/ForgetPassword";
// import RecoveryPassword from "../pages/AuthPages/RecoveryPassword";
// import RecoverySuccess from "../pages/AuthPages/RecoverySuccess";

function Routes() {
  const renderLoader = () => (
    <p>
      Carregando...
      <br />
      Em caso de demora atualize a página!!!
    </p>
  );

  const Landing = lazy(() => import("../pages/AuthPages/Landing"));
  const Login = lazy(() => import("../pages/AuthPages/Login"));
  const Subscription = lazy(() => import("../pages/AuthPages/Subscription"));
  const DemandsShow = lazy(() => import("../pages/AuthPages/DemandsShow"));
  const AppealsShow = lazy(() => import("../pages/AuthPages/AppealsShow"));
  const SubscriptionCpf = lazy(
    () => import("../pages/AuthPages/SubscriptionCpf")
  );
  const ForgetPassword = lazy(
    () => import("../pages/AuthPages/ForgetPassword")
  );
  const RecoveryPassword = lazy(
    () => import("../pages/AuthPages/RecoveryPassword")
  );
  const RecoverySuccess = lazy(
    () => import("../pages/AuthPages/RecoverySuccess")
  );

  return (
    <BrowserRouter>
      <Suspense fallback={renderLoader()}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/subscription" component={Subscription} />
          <Route
            exact
            path="/demands-show/:showToken"
            component={DemandsShow}
          />
          <Route
            exact
            path="/appeals-show/:showToken"
            component={AppealsShow}
          />
          <Route exact path="/subscriptionCpf" component={SubscriptionCpf} />
          <Route
            exact
            path="/subscriptionCpf2"
            component={() => (
              <h1>
                O Sistema de Cadastro ainda não foi liberado, aguarde a data de
                inscrição de algum dos editais abertos!!!
              </h1>
            )}
          />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route
            exact
            path="/recovery-password/:token"
            component={RecoveryPassword}
          />
          <Route exact path="/recovery-success" component={RecoverySuccess} />
          <Route exact path="/" component={Landing} />

          <Route
            exact
            path="/*"
            component={() => <Redirect to={{ pathname: "/" }} />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;

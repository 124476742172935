import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { useAuth } from "../contexts/auth";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { signed } = useAuth();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        signed ? (
          props.path === "/login" ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            <Component {...routeProps} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

function Routes() {
  const renderLoader = () => (
    <p>
      Carregando...
      <br />
      Em caso de demora atualize a página!!!
    </p>
  );
  const LandingAdmin = lazy(() => import("../pages/AdminPages/LandingAdmin"));
  const AppealsResp = lazy(() => import("../pages/AdminPages/AppealsResp"));
  const RolesQuery = lazy(() => import("../pages/AdminPages/RolesQuery"));
  const RolesPhysicalQuery = lazy(() => import("../pages/AdminPages/RolesPhysicalQuery"));
  const FilesQuery = lazy(() => import("../pages/AdminPages/FilesQuery"));
  const AnulationsQuery = lazy(() => import("../pages/AdminPages/AnulationsQuery"));
  const AppealsQuery = lazy(() => import("../pages/AdminPages/AppealsQuery"));
  const DemandsQuery = lazy(() => import("../pages/AdminPages/DemandsQuery"));
  const DateProcessing = lazy(
    () => import("../pages/AdminPages/DateProcessing")
  );
  const UploadProcessing = lazy(
    () => import("../pages/AdminPages/UploadProcessing")
  );
  const InformationProcessing = lazy(
    () => import("../pages/AdminPages/InformationProcessing")
  );
  const RolesPublicationQuery = lazy(
    () => import("../pages/AdminPages/RolesPublicationQuery")
  );
  const DemandsResp = lazy(() => import("../pages/AdminPages/DemandsResp"));
  const DemandsRespError = lazy(
    () => import("../pages/AdminPages/DemandsRespError")
  );
  const PhysicalDemandsResp = lazy(
    () => import("../pages/AdminPages/PhysicalDemandsResp")
  );
  const UsersReg = lazy(() => import("../pages/AdminPages/UsersReg"));
  const ReturnFile = lazy(() => import("../pages/AdminPages/ReturnFile"));
  const AppealsContestsResp = lazy(
    () => import("../pages/AdminPages/AppealsContestsResp")
  );
  const RolesReg = lazy(() => import("../pages/AdminPages/RolesReg"));
  const AppealsContestConfig = lazy(
    () => import("../pages/AdminPages/AppealsContestConfig")
  );
  const DemandsContestConfig = lazy(
    () => import("../pages/AdminPages/DemandsContestConfig")
  );
  const AppealsReg = lazy(() => import("../pages/AdminPages/AppealsReg"));
  const BlocksReg = lazy(() => import("../pages/AdminPages/BlocksReg"));
  const RoomsReg = lazy(() => import("../pages/AdminPages/RoomsReg"));
  const AnswersReg = lazy(() => import("../pages/AdminPages/AnswersReg"));
  const DemandsReg = lazy(() => import("../pages/AdminPages/DemandsReg"));
  const ContestsReg = lazy(() => import("../pages/AdminPages/ContestsReg"));
  const AccountsReg = lazy(() => import("../pages/AdminPages/AccountsReg"));

  return (
    <BrowserRouter>
      <Suspense fallback={renderLoader()}>
        <Switch>
          <PrivateRoute
            exact
            path="/appeals-contests-resp"
            component={AppealsContestsResp}
          />
          <PrivateRoute exact path="/return-file" component={ReturnFile} />
          <PrivateRoute exact path="/users-reg" component={UsersReg} />
          <PrivateRoute exact path="/blocks-reg" component={BlocksReg} />
          <PrivateRoute exact path="/rooms-reg" component={RoomsReg} />
          <PrivateRoute exact path="/roles-query" component={RolesQuery} />
          <PrivateRoute exact path="/roles-physical-query" component={RolesPhysicalQuery} />
          <PrivateRoute exact path="/files-query" component={FilesQuery} />
          <PrivateRoute exact path="/anulations-query" component={AnulationsQuery} />
          <PrivateRoute exact path="/appeals-query" component={AppealsQuery} />
          <PrivateRoute exact path="/demands-query" component={DemandsQuery} />
          <PrivateRoute
            exact
            path="/date-processing"
            component={DateProcessing}
          />
          <PrivateRoute
            exact
            path="/upload-processing"
            component={UploadProcessing}
          />
          <PrivateRoute
            exact
            path="/information-processing"
            component={InformationProcessing}
          />

          <PrivateRoute
            exact
            path="/roles-publication-query"
            component={RolesPublicationQuery}
          />
          <PrivateRoute exact path="/demands-resp" component={DemandsResp} />
          <PrivateRoute
            exact
            path="/demands-resp-error"
            component={DemandsRespError}
          />
          <PrivateRoute
            exact
            path="/physical-resp"
            component={PhysicalDemandsResp}
          />
          <PrivateRoute exact path="/appeals-resp" component={AppealsResp} />
          <PrivateRoute
            exact
            path="/appeals-contest-config"
            component={AppealsContestConfig}
          />
          <PrivateRoute
            exact
            path="/demands-contest-config"
            component={DemandsContestConfig}
          />
          <PrivateRoute exact path="/roles-reg" component={RolesReg} />
          <PrivateRoute exact path="/accounts-reg" component={AccountsReg} />
          <PrivateRoute exact path="/contests-reg" component={ContestsReg} />
          <PrivateRoute exact path="/appeals-reg" component={AppealsReg} />
          <PrivateRoute exact path="/answers-reg" component={AnswersReg} />
          <PrivateRoute exact path="/demands-reg" component={DemandsReg} />
          <PrivateRoute exact path="/" component={LandingAdmin} />
          <PrivateRoute
            path="*"
            component={() => <Redirect to={{ pathname: "/" }} />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;

import React from "react";

import "./styles.css";
import loadIcon from "../../../src/assets/images/gif-load-2.gif";

interface PageHeaderInProps {
  title: string;
  description?: string;
}

const PageHeaderIn: React.FC<PageHeaderInProps> = (props) => {
  return (
    <div className="status-page">
      <div className="status-background">
        <img src={loadIcon} alt="Loading" />
        <div className="header-content">
          <h2>{props.title}</h2>
          {props.description && <p>{props.description}</p>}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default PageHeaderIn;

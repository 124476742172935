import React from "react";

import "./assets/styles/global.css";
import Routes from "./routes";
import { AuthProvider } from "./contexts/auth";
import IdleTimerContainer from "./components/IdleTimer/IdleTimerContainer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
    >
      <AuthProvider>
        <IdleTimerContainer></IdleTimerContainer>
        <Routes />
      </AuthProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;

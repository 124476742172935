import axios from "axios";
import React, { createContext, useState, useEffect, useContext } from "react";
import api from "../services/api";

export interface UserData {
  id: string;
  name: string;
  cpf: string;
  password: string;
  dt_nasc: string;
  rg: string;
  rg_org: string;
  rg_uf: string;
  sexo: string;
  cep: string;
  cidade: string;
  uf: string;
  bairro: string;
  address: string;
  address_nro: string;
  address_comp: string;
  email: string;
  fone_celular: string;
  fone_contato: string;
  conc_def_fisica: string;
  conc_def_fisica_description: string;
}

interface AuthContextData {
  signed: boolean;
  isAdmin: boolean;
  user: UserData | null;
  loading: boolean;
  // statusServer: boolean;
  signIn(cpf: string, password: string, rcToken: string): Promise<() => void>;
  signUp(
    name: string,
    cpf: string,
    password: string,
    dt_nasc: string,
    rg: string,
    rg_org: string,
    rg_uf: string,
    sexo: string,
    cep: string,
    cidade: string,
    uf: string,
    bairro: string,
    address: string,
    address_nro: string,
    address_comp: string,
    email: string,
    fone_celular: string,
    fone_contato: string,
    conc_def_fisica: string,
    conc_def_fisica_description: string,
    file_id: number,
    rcToken: string
  ): Promise<void>;
  signOut(): void;
  contactServer?(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const isLoaded = React.useRef(false);

  useEffect(() => {
    async function loadSoragedData() {
      isLoaded.current = true;
      if (isLoaded.current) {
        const storagedUserSession = await sessionStorage.getItem(
          "@SInCoAuthSession:user"
        );
        const storagedTokenSession = await sessionStorage.getItem(
          "@SInCoAuthSession:token"
        );
        const storagedGrpSession = await sessionStorage.getItem(
          "@SInCoAuthSession:grp"
        );

        if (storagedUserSession && storagedTokenSession) {
          api.defaults.headers[
            "Authorization"
          ] = `Bearer ${storagedTokenSession}`;
          if (storagedGrpSession === "e61bc1ad2bcc6e3104e2737bd60a0e31") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }

          setUser(JSON.parse(storagedUserSession));
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
      isLoaded.current = false;
    }

    loadSoragedData();

    return () => {
      isLoaded.current = false;
    };
  }, []);

  async function setUserAndToken(
    user: UserData,
    token: string,
    isAdmin: boolean
  ) {
    setUser(user);
    let grp = "";
    if (isAdmin) {
      grp = "e61bc1ad2bcc6e3104e2737bd60a0e31";
    } else {
      grp = "c76ccd86e4594f22141140cbf8a701c3";
    }

    sessionStorage.setItem("@SInCoAuthSession:user", JSON.stringify(user));
    sessionStorage.setItem("@SInCoAuthSession:grp", grp);
    sessionStorage.setItem("@SInCoAuthSession:token", JSON.stringify(token));

    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  async function signIn(cpf: string, password: string, rcToken: string) {
    const sourceIn = axios.CancelToken.source();

    const response = await api.post(
      "/login",
      { cpf, password, rcToken },
      { cancelToken: sourceIn.token }
    );
    setIsAdmin(response.data.isAdmin);

    await setUserAndToken(
      response.data.user,
      response.data.token,
      response.data.isAdmin
    );

    return () => {
      sourceIn.cancel();
    };
  }

  async function physicalDemand(
    file_id: number,
    user_id: string,
    physical_demand: string,
    explanation: string
  ) {
    const dataDem = {};
    Object.assign(dataDem, { file_id });
    Object.assign(dataDem, { user_id });
    Object.assign(dataDem, { physical_demand });
    Object.assign(dataDem, { explanation });

    await api.post("/physical_demands", dataDem);
  }

  async function signUp(
    name: string,
    cpf: string,
    password: string,
    dt_nasc: string,
    rg: string,
    rg_org: string,
    rg_uf: string,
    sexo: string,
    cep: string,
    cidade: string,
    uf: string,
    bairro: string,
    address: string,
    address_nro: string,
    address_comp: string,
    email: string,
    fone_celular: string,
    fone_contato: string,
    conc_def_fisica: string,
    conc_def_fisica_description: string,
    file_id: number,
    rcToken: string
  ) {
    if (!!!user) {
      await api
        .post("/signup", {
          name,
          cpf,
          password,
          dt_nasc,
          rg,
          rg_org,
          rg_uf,
          sexo,
          cep,
          cidade,
          uf,
          bairro,
          address,
          address_nro,
          address_comp,
          email,
          fone_celular,
          fone_contato,
          conc_def_fisica,
          conc_def_fisica_description,
          file_id,
        })
        .then((response) => {
          if (!!conc_def_fisica) {
            physicalDemand(
              file_id,
              response.data.user[0].id,
              conc_def_fisica,
              conc_def_fisica_description
            );
          }

          alert("Usuário cadastrado com sucesso!!!");
          // signIn(cpf, password, rcToken);
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
          alert("Erro ao cadastrar usuário, favor tentar novamente!!!");
          return;
        });
    } else {
      await api
        .put(`/users/${user.id}`, {
          name,
          cpf,
          password,
          dt_nasc,
          rg,
          rg_org,
          rg_uf,
          sexo,
          cep,
          cidade,
          uf,
          bairro,
          address,
          address_nro,
          address_comp,
          email,
          fone_celular,
          fone_contato,
          conc_def_fisica,
          conc_def_fisica_description,
          file_id,
        })
        .then((response) => {
          physicalDemand(
            file_id,
            response.data.id,
            conc_def_fisica,
            conc_def_fisica_description
          );
          alert("Dados atualizados com sucesso!!!");
          signIn(cpf, password, rcToken);
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function signOut() {
    sessionStorage.clear();
    setIsAdmin(false);
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        isAdmin: isAdmin,
        user,
        loading,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import { useAuth } from "../../contexts/auth";

function IdleTimerContainer() {
  const idleTimerRef = useRef(null);
  const { signed, signOut } = useAuth();

  const onIdle = () => {
    if (signed) {
      signOut();
    }
  };

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={30 * 60 * 1000}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
}

export default IdleTimerContainer;

import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { useAuth } from "../contexts/auth";

// import Registration from "../pages/SincoPages/Registration";
// import Contests from "../pages/SincoPages/Contests";
// import RegistrationConfirm from "../pages/SincoPages/RegistrationConfirm";
// import CreateBankSlip from "../pages/SincoPages/CreateBankSlip";
// import Demands from "../pages/SincoPages/Demands";
// import Appeals from "../pages/SincoPages/Appeals";
// import Profile from "../pages/SincoPages/Profile";
// import AppealsContests from "../pages/SincoPages/AppealsContests";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { signed } = useAuth();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        signed ? (
          props.path === "/login" ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            <Component {...routeProps} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

function Routes() {
  const renderLoader = () => (
    <p>
      Carregando...
      <br />
      Em caso de demora atualize a página!!!
    </p>
  );
  const Registration = lazy(() => import("../pages/SincoPages/Registration"));
  const Contests = lazy(() => import("../pages/SincoPages/Contests"));
  const Anulations = lazy(() => import("../pages/SincoPages/Anulations"));
  const RegistrationConfirm = lazy(
    () => import("../pages/SincoPages/RegistrationConfirm")
  );
  const CreateBankSlip = lazy(
    () => import("../pages/SincoPages/CreateBankSlip")
  );
  const Demands = lazy(() => import("../pages/SincoPages/Demands"));
  const PhysicalDemands = lazy(
    () => import("../pages/SincoPages/PhysicalDemands")
  );
  const Appeals = lazy(() => import("../pages/SincoPages/Appeals"));
  const Profile = lazy(() => import("../pages/SincoPages/Profile"));
  const AppealsContests = lazy(
    () => import("../pages/SincoPages/AppealsContests")
  );

  return (
    <BrowserRouter>
      <Suspense fallback={renderLoader()}>
        <Switch>
          <PrivateRoute exact path="/" component={Registration} />
          <PrivateRoute exact path="/contests" component={Contests} />
          <PrivateRoute exact path="/anulations" component={Anulations} />
          <PrivateRoute
            exact
            path="/registration-confirm"
            component={RegistrationConfirm}
          />
          <PrivateRoute
            exact
            path="/create-bank-slip"
            component={CreateBankSlip}
          />
          <PrivateRoute exact path="/demands" component={Demands} />
          <PrivateRoute
            exact
            path="/physical-demands"
            component={PhysicalDemands}
          />
          <PrivateRoute exact path="/appeals" component={Appeals} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute
            exact
            path="/appeals-contests"
            component={AppealsContests}
          />
          <PrivateRoute
            path="/login"
            component={() => <h1>Você está Logado!!!</h1>}
          />
          <Route
            path="*"
            component={() => <Redirect to={{ pathname: "/" }} />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
